import { margin } from "ds_legacy/materials/metrics";
import { Caption, FONT_SIZE_12 } from "ds_legacy/materials/typography";
import { useTranslations } from "translations";
import Translations from "translations/types";

export const LOGIN_ERROR_TYPE_NOT_ACTIVE = 1;
export const LOGIN_ERROR_TYPE_DOES_NOT_EXIST = 2;
export const SIGNUP_ERROR_TYPE_ALREADY_EXISTS = 3;

export const LOGIN_ERROR_TYPES = [
  LOGIN_ERROR_TYPE_NOT_ACTIVE,
  LOGIN_ERROR_TYPE_DOES_NOT_EXIST,
  SIGNUP_ERROR_TYPE_ALREADY_EXISTS,
] as const;

export type LoginErrorType = (typeof LOGIN_ERROR_TYPES)[number];

export const getLoginErrorConfig = (
  error: LoginErrorType | undefined,
  translations: Translations,
): { translation: string } | null => {
  if (!error) return null;
  switch (error) {
    case LOGIN_ERROR_TYPE_NOT_ACTIVE:
      return {
        translation:
          translations.providersearch.loginModal.loginModal
            .errorAccountNotActive,
      };
    case LOGIN_ERROR_TYPE_DOES_NOT_EXIST:
      return {
        translation:
          translations.providersearch.loginModal.loginModal
            .errorAccountDoesNotExist,
      };
    case SIGNUP_ERROR_TYPE_ALREADY_EXISTS:
      return {
        translation:
          translations.providersearch.loginModal.createAccount
            .alreadyExistsMessage,
      };
    default: {
      console.error(`missing error configuration for error type: ${error}`);
      return null;
    }
  }
};

export const LoginErrors = ({
  error,
}: {
  error: LoginErrorType | undefined;
}) => {
  const translations = useTranslations();
  const config = getLoginErrorConfig(error, translations);

  if (!config) return null;

  return (
    <Caption
      as="p"
      role="alert"
      data-testid="login-error"
      margin={margin(0)}
      fontSize={FONT_SIZE_12}
      whiteSpace="normal"
      error
    >
      {config.translation}
    </Caption>
  );
};
