import { useProviderSearchContext } from "context/ProviderSearchContext";
import {
  PROVIDER_SEARCH_VERSION_BAVARIA,
  REDIRECT_REASON_NOT_AUTHORIZED,
  REDIRECT_REASON_PARAM,
  RETURN_PARAM,
} from "core/consts";
import { activateProviderSearchTableOfContents } from "core/model/utils/featureFlags";
import { getReferrer } from "core/model/utils/urls";
import { GenericErrorPage } from "dsl/atoms/ErrorPages";
import RedirectRoot from "dsl/atoms/RedirectRoot";
import lazy from "dsl/atoms/retry";
import LogoutPage from "dsl/ecosystems/LogoutPage";
import { PrintableProviderSearchListing } from "dsl/ecosystems/ProviderSearchListingPage/PrintableProviderSearchListing";
import {
  WIZARD_TYPE_ACTIVATION,
  WIZARD_TYPE_LOGIN,
} from "dsl/ecosystems/ProviderSearchLoginWizard";
import ProviderSearchOnboarding from "dsl/ecosystems/ProviderSearchOnboarding";
import { useProvidersearchNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import {
  ProviderSearchFiltersWrapper,
  ProviderSearchHome,
  ProviderSearchRootPage,
  useProviderSearchFilterContext,
} from "dsl/molecules/ProviderSearchAppWrapper";
import { USER_FILTER_TREATMENT_LOCATION } from "dsl/organisms/Filters/consts";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const ProviderSearchDashboardPage = lazy(
  () => import("dsl/ecosystems/ProviderSearchDashboard"),
);
const ProviderSearchDetailsPage = lazy(
  () => import("dsl/ecosystems/ProviderSearchDetailsPage"),
);
const ProviderSearchRequestPage = lazy(
  () => import("dsl/ecosystems/ProviderSearchRequestPage"),
);
const ProviderSearchListingPage = lazy(
  () => import("dsl/ecosystems/ProviderSearchListingPage"),
);
const ProviderSearchLoginWizard = lazy(
  () => import("dsl/ecosystems/ProviderSearchLoginWizard"),
);
const ProviderSearchAccountPage = lazy(
  () => import("dsl/ecosystems/ProviderSearchAccountPage"),
);
const ProviderSearchTableOfContents = lazy(
  () => import("dsl/ecosystems/ProviderSearchTableOfContents"),
);
const ProviderSearchGlossary = lazy(
  () => import("dsl/ecosystems/ProviderSearchGlossary"),
);

function AuthWrapper() {
  const { isLoggedIn, setLoginModal } = useProviderSearchContext();
  const { goToOnboardingFunnel } = useProvidersearchNavigationHandlers();
  const notify = useNotification();
  const [search] = useSearchParams();
  const ref = getReferrer();

  useEffect(() => {
    if (isLoggedIn) return;

    const redirect = encodeURIComponent(
      `${location.pathname}?${search.toString()}`,
    );
    const searchString = `?${RETURN_PARAM}=${redirect}&${REDIRECT_REASON_PARAM}=${REDIRECT_REASON_NOT_AUTHORIZED}`;

    goToOnboardingFunnel(searchString);
  }, [isLoggedIn, ref, setLoginModal, notify, goToOnboardingFunnel]);

  if (!isLoggedIn) {
    return null;
  }

  return <Outlet />;
}

export const ZIPCODE_REDIRECT_PARAM = "return_url";

const hasZipcodeInParams = (search: URLSearchParams) =>
  search.get(USER_FILTER_TREATMENT_LOCATION);

function ZipcodeCondition() {
  const context = useProviderSearchFilterContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  useEffect(() => {
    if (!hasZipcodeInParams(search)) {
      const redirect = encodeURIComponent(location.pathname);
      setTimeout(() => {
        navigate(
          {
            pathname: ``,
            search: `${ZIPCODE_REDIRECT_PARAM}=${redirect}`,
          },
          { replace: true },
        );
      }, 0);
    }
  }, [context, location, navigate, search]);

  if (!hasZipcodeInParams(search)) {
    return null;
  }

  return <Outlet context={context} />;
}

export default function ProviderSearchRoutes() {
  // TODO: get based on domain
  const defaultProviderSearch = PROVIDER_SEARCH_VERSION_BAVARIA;

  return (
    <Routes>
      <Route path="/*" element={<ProviderSearchRootPage />}>
        {/* Logout page */}
        <Route
          path="logout"
          element={
            <LogoutPage
              navigateTo={`providersearch/${defaultProviderSearch}/listing`}
            />
          }
        />

        {/* Error page */}
        <Route path="error" element={<GenericErrorPage />} />

        {/* Account login route */}
        <Route
          path="providersearch/loginwizard"
          element={<ProviderSearchLoginWizard wizardType={WIZARD_TYPE_LOGIN} />}
        />

        {/* Account activation route */}
        <Route
          path="providersearch/activationwizard"
          element={
            <ProviderSearchLoginWizard wizardType={WIZARD_TYPE_ACTIVATION} />
          }
        />

        <Route
          path="providersearch/:provider_search/*"
          element={<ProviderSearchHome />}
        >
          {activateProviderSearchTableOfContents && (
            <Route
              path="tableofcontents"
              element={<ProviderSearchTableOfContents />}
            />
          )}
          <Route
            path="glossary/:section"
            element={<ProviderSearchGlossary />}
          />
          <Route index element={<ProviderSearchOnboarding />} />

          {/* these routes will have the filter state forced into the search parameters */}
          <Route element={<ProviderSearchFiltersWrapper />}>
            {/* Routes wrapped in ZipcodeCondition require zipcode to be set in the search parameters */}
            <Route element={<ZipcodeCondition />}>
              {/* Listing/Landing Page */}
              <Route path="listing" element={<ProviderSearchListingPage />} />
              <Route
                path="printable-listing"
                element={<PrintableProviderSearchListing />}
              />

              {/* Consultant Detail Page */}
              <Route
                path="consultant/:consultant_id"
                element={<ProviderSearchDetailsPage />}
              />

              {/* Provider Detail Page */}
              <Route
                path="provider/:careprovider_id"
                element={<ProviderSearchDetailsPage />}
              />

              {/* Print Provider Detail Page */}
              <Route
                path="print-provider/:careprovider_id"
                element={<ProviderSearchDetailsPage />}
              />

              {/* Print Consultant Detail Page */}
              <Route
                path="print-consultant/:consultant_id"
                element={<ProviderSearchDetailsPage />}
              />
            </Route>
          </Route>

          {/* Authenticated routes */}
          <Route element={<AuthWrapper />}>
            {/* Dashboard Page */}
            <Route
              path="dashboard/patient/:patient_id"
              element={<ProviderSearchDashboardPage />}
            />
            {/* Provider Request Page */}
            <Route
              path="request/:auction_request_id"
              element={<ProviderSearchRequestPage />}
            />
            <Route
              path="account/:account_id"
              element={<ProviderSearchAccountPage />}
            />
          </Route>
          <Route path="*" element={<RedirectRoot to="listing" />} />
        </Route>
        <Route
          path="*"
          element={
            <RedirectRoot
              to={`providersearch/${defaultProviderSearch}/listing`}
            />
          }
        />
      </Route>
    </Routes>
  );
}
