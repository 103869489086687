import { Grid, Theme, useTheme } from "@mui/material";
import {
  CAPACITY_STATUS_FREE,
  CAPACITY_STATUS_FULL,
  CAPACITY_STATUS_UNKNOWN,
  TRACK_EVENTS,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { convertMetertoKm } from "core/model/utils/location";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { grammaticalList } from "core/model/utils/strings";
import { CapacityStatus, ProviderListing } from "core/types";
import { format, fromUnixTime } from "date-fns";
import Tooltip from "ds_legacy/components/Tooltip";
import { KEYBOARD_FOCUS_OUTLINE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding, sizing } from "ds_legacy/materials/metrics";
import { SHADOW_DP5 } from "ds_legacy/materials/shadows";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  LINE_HEIGHT_20,
  Title,
} from "ds_legacy/materials/typography";
import Image from "dsl/atoms/Image";
import { useHasOverFlown } from "dsl/hooks";
import { useProvidersearchNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

const PROVIDER_CARD_HEIGHT = dp(200);
const CONSULTANT_CARD_HEIGHT = sizing(16);

const ProviderTitle = styled(Title)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  font-size: ${FONT_SIZE_18};
  margin: ${margin(0)};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const ProviderSearchCardWrapper = styled.div<{
  $borderColor: string;
  $isConsultant?: boolean;
}>`
  background-color: ${({ $isConsultant, theme }) =>
    $isConsultant ? theme.palette.neutrals.custom_white : ""};
  border-color: ${(props) => props.theme.palette.neutrals.medium_grey};
  border-radius: ${dp(8)};
  border-right-color: ${(props) => props.$borderColor};
  border-style: solid;
  border-width: ${dp(1)} ${dp(8)} ${dp(1)} ${dp(1)};
  color: inherit;
  cursor: pointer;
  display: flex;
  list-style: none;
  margin: ${margin(0, 0, 2)};
  min-height: ${({ $isConsultant }) =>
    $isConsultant ? CONSULTANT_CARD_HEIGHT : PROVIDER_CARD_HEIGHT};
  overflow-x: auto;
  position: relative;
  text-decoration: none;

  &:hover {
    box-shadow: ${SHADOW_DP5};
  }
  &:focus-within {
    outline: ${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE};
  }
`;

export const getCardConfig = (
  theme: Theme,
  translations: Translations,
  capacity?: CapacityStatus,
  is_consultant?: boolean,
): {
  color: string;
  fontWeight?: CSSProperties["fontWeight"];
  icon: {
    alt: string;
    path: string;
  };
  label: string;
} => {
  if (is_consultant) {
    return {
      icon: {
        path: "info_center_x2.png",
        alt: translations.providersearch.map.legendConsultant,
      },
      color: theme.palette.neutrals.custom_blue,
      label:
        translations.providersearch.listingPage.consultantCard
          .appointmentInformation,
    };
  }

  switch (capacity) {
    case CAPACITY_STATUS_FREE:
      return {
        icon: {
          path: "care_provider_x2.png",
          alt: translations.providersearch.map.legendProvider,
        },
        color: theme.palette.success.main,
        label:
          translations.providersearch.listingPage.providerCard.capacity
            .freeCapacity,
        fontWeight: FONT_WEIGHT_BOLD,
      };
    case CAPACITY_STATUS_FULL:
      return {
        icon: {
          path: "not_available_x2.png",
          alt: translations.providersearch.map.legendNotAvailable,
        },
        color: theme.palette.error.main,
        label:
          translations.providersearch.listingPage.providerCard.capacity
            .noCapacity,
        fontWeight: FONT_WEIGHT_BOLD,
      };
    case CAPACITY_STATUS_UNKNOWN:
      return {
        icon: {
          path: "care_provider_x2.png",
          alt: translations.providersearch.map.legendProvider,
        },
        color: theme.palette.warning.main,
        label:
          translations.providersearch.listingPage.providerCard.capacity
            .capacityUnknown,
        fontWeight: FONT_WEIGHT_BOLD,
      };
    default:
      return {
        icon: {
          path: "care_provider_x2.png",
          alt: translations.providersearch.map.legendProvider,
        },
        color: theme.palette.neutrals.medium_grey,
        label: "",
      };
  }
};

const ImageSection = ({
  isConsultant,
  thumbnailUrl,
}: {
  isConsultant?: boolean;
  thumbnailUrl: string | undefined;
}) => {
  if (isConsultant) return null;

  return (
    <Grid
      item
      height={PROVIDER_CARD_HEIGHT}
      minWidth={sizing(30)}
      maxWidth={sizing(30)}
      display={{ xs: "none", desktop: "block" }}
    >
      <img
        alt="" // hide from screenreaders
        data-testid="provider-image"
        src={thumbnailUrl}
        style={{ height: "100%", width: "100%" }}
      />
    </Grid>
  );
};

const DistanceZipcodeInfo = ({
  city,
  distance,
  zipcode,
}: {
  city: string | undefined;
  distance: number | undefined;
  zipcode: string | undefined;
}) => {
  const translations = useTranslations();
  const theme = useTheme();
  const distanceInKm = convertMetertoKm(distance ?? 0);
  const distanceLabel = `, ${distanceInKm} km ${translations.bToC.providerList.away}`;

  if (!city || !zipcode) return null;

  return (
    <Body
      as="p"
      data-testid="provider-location"
      margin={margin(0)}
      fontSize={FONT_SIZE_14}
      fontWeight={FONT_WEIGHT_BOLD}
      color={theme.palette.neutrals.dark_grey}
    >
      {distanceInKm
        ? `${zipcode} ${city}${distanceLabel}`
        : `${translations.providersearch.providerDetailsPage.contactSection.addressDistanceZero(
            { zipcode },
          )}, ${city}`}
    </Body>
  );
};

const ProviderContentTitle = ({
  careprovider,
}: {
  careprovider: ProviderListing;
}) => {
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const { hasOverFlown, ref } = useHasOverFlown<HTMLHeadingElement>({
    direction: "y",
    value: careprovider.name,
  });
  const { goToConsultant, goToProvider } = useProvidersearchNavigationUrls();

  return (
    <>
      <Tooltip title={hasOverFlown ? careprovider.name : ""}>
        <ProviderTitle
          as="h3"
          bold
          color={theme.palette.neutrals.base_dark}
          ref={ref}
          data-testid="provider-name"
        >
          <StyledLink
            onClick={() =>
              trackEvent({
                name: careprovider.is_consultant
                  ? TRACK_EVENTS.PROVIDER_SEARCH_CONSULTANT_CARD_CLICKED
                  : TRACK_EVENTS.PROVIDER_SEARCH_PROVIDER_CARD_CLICKED,
              })
            }
            to={
              careprovider.is_consultant
                ? goToConsultant({ consultantId: careprovider.id })
                : goToProvider({ careproviderId: careprovider.id })
            }
          >
            {careprovider.name}
          </StyledLink>
        </ProviderTitle>
      </Tooltip>
      {careprovider.project_sponsor && (
        <Body
          as="p"
          margin={margin(0)}
          fontSize={FONT_SIZE_14}
          fontWeight={FONT_WEIGHT_BOLD}
        >
          {translations.providersearch.listingPage.consultantCard.agency({
            sponsorName: careprovider.project_sponsor,
          })}
        </Body>
      )}
      <DistanceZipcodeInfo
        distance={careprovider.distance}
        zipcode={careprovider.address?.zip_code}
        city={careprovider.address?.city}
      />
    </>
  );
};

const MAX_FACILITIES_TO_SHOW = 3;

const ProviderFacilitiesSection = ({
  facilities,
}: {
  facilities: Array<number> | undefined;
}) => {
  const theme = useTheme();
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const itemsToShow = facilities?.slice(0, MAX_FACILITIES_TO_SHOW);
  let string = "";

  if (facilities && facilities?.length > MAX_FACILITIES_TO_SHOW) {
    string = `${itemsToShow
      ?.map((item) => getOntology({ type: "facility", key: item }))
      .join(", ")} ${
      translations.providersearch.listingPage.providerCard.moreProviders
    }`;
  } else {
    string = grammaticalList({
      array: itemsToShow,
      ontologyType: "facility",
      getOntology,
      translations,
    });
  }

  return (
    <Body
      as="p"
      breakWord
      color={theme.palette.neutrals.base_dark}
      data-testid="provider-facility"
      fontSize={FONT_SIZE_14}
      fontWeight={FONT_WEIGHT_REGULAR}
      lineHeight={LINE_HEIGHT_20}
      margin={margin(0)}
      maxWidth="100%"
    >
      {string}
    </Body>
  );
};

const ProviderCapacityStatus = ({
  capacityStatus,
  isConsultant,
  updatedAt,
}: {
  capacityStatus?: CapacityStatus;
  isConsultant?: boolean;
  updatedAt?: number | null;
}) => {
  const { currentLocale: locale } = useDateLocale();
  const theme = useTheme();
  const translations = useTranslations();
  const { fontWeight, icon, label } = getCardConfig(
    theme,
    translations,
    capacityStatus,
    isConsultant,
  );
  const capacityUpdatedAtLabel = updatedAt
    ? translations.providersearch.listingPage.providerCard.capacity.capacityUpdatedAt(
        { updatedAt: format(fromUnixTime(updatedAt), "P", { locale }) },
      )
    : "";

  return (
    <>
      <VerticalLayout maxWidth="100%">
        <Body
          as="p"
          fontWeight={fontWeight}
          data-testid="provider-capacity"
          margin={margin(0, 1, 0, 0)}
          fontSize={FONT_SIZE_14}
          color={theme.palette.neutrals.base_dark}
          lineHeight={LINE_HEIGHT_20}
          breakWord
          textAlign="end"
        >
          {label}
        </Body>
        <Body
          as="p"
          margin={margin(0, 1, 0, 0)}
          fontSize={FONT_SIZE_14}
          color={theme.palette.neutrals.dark_grey}
          lineHeight={LINE_HEIGHT_20}
          textAlign="end"
          breakWord
        >
          {capacityUpdatedAtLabel}
        </Body>
      </VerticalLayout>

      <Image path={icon.path} alt={icon.alt} style={{ width: dp(24) }} />
    </>
  );
};

const ProviderContentSection = ({
  careprovider,
}: {
  careprovider: ProviderListing;
}) => {
  return (
    <Grid
      item
      container
      direction="column"
      padding={padding(2, 1.5)}
      wrap="nowrap"
    >
      <Grid item xs={12}>
        <ProviderContentTitle careprovider={careprovider} />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" columnGap={4}>
        <Grid item container xs={12} sm alignItems="flex-end">
          <ProviderFacilitiesSection facilities={careprovider.facilities} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          justifyContent="flex-end"
          alignItems="flex-center"
          display="flex"
          minWidth={dp(200)}
          sx={{ marginTop: "auto" }}
          flexWrap="nowrap"
        >
          <ProviderCapacityStatus
            capacityStatus={careprovider.profile?.capacity?.capacity_status}
            updatedAt={careprovider.profile?.capacity?.updated_at}
            isConsultant={careprovider.is_consultant}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ProviderSearchCard = ({
  careprovider,
}: {
  careprovider: ProviderListing;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  const { color } = getCardConfig(
    theme,
    translations,
    careprovider.profile?.capacity?.capacity_status,
    careprovider.is_consultant,
  );

  return (
    <ProviderSearchCardWrapper
      data-testid={`provider-card-${careprovider.id}`}
      $borderColor={color}
      $isConsultant={careprovider.is_consultant}
    >
      <Grid container wrap="nowrap" overflow="hidden">
        <ImageSection
          thumbnailUrl={careprovider.thumbnail_url}
          isConsultant={careprovider.is_consultant}
        />
        <ProviderContentSection careprovider={careprovider} />
      </Grid>
    </ProviderSearchCardWrapper>
  );
};
