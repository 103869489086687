import { visuallyHidden } from "@mui/utils";
import {
  SOLUTION_ASSISTED_LIVING,
  SOLUTION_DAILY_LIVING_SUPPORT_SERVICES,
  SOLUTION_DAY_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_HOSPICE_ASSOCIATION,
  SOLUTION_HOSPICE_MOBILE,
  SOLUTION_HOSPICE_PARTIALLY_MOBILE,
  SOLUTION_HOSPICE_STATIC,
  SOLUTION_MOBILE_CARE,
  SOLUTION_NIGHT_CARE_MOBILE,
  SOLUTION_NIGHT_CARE_STATIC,
  SOLUTION_SHARED_FLAT,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  TRACK_EVENTS,
} from "core/consts";
import { FilterValueSolutions } from "core/types";
import ChipGroupPresenter, {
  ChipGroupPresenterProps,
} from "ds_legacy/components/ChipGroup";
import RSButton from "ds_legacy/components/RSButton";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_20 } from "ds_legacy/materials/typography";
import { usePageMetaData } from "dsl/hooks";
import { useGetProviderSearchRedirectLink } from "dsl/hooks/useGetProviderSearchRedirectLink";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";
import { Iterable } from "immutable";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import {
  FormStepWrapper,
  FunnelFormData,
  FunnelSteps,
  InfoTextWithGlossaryLink,
  STEP_CONSULTANT_PROVIDER_SELECTION,
  STEP_SERVICES,
  STEP_SOLUTIONS,
  getAccessibleTitleWithStep,
} from "../common";

const CATEGORY_MOBILE = 1;
const CATEGORY_STATIC = 2;
const CATEGORY_HELP_AT_HOME = 3;
const CATEGORY_ASSISTED = 4;
const CATEGORY_HOSPICE = 5;
const CATEGORY_OTHER = 6;
const CATEGORY_SHORT_TERM_STATIC = 7;

export const PSEUDO_SOLUTION_OTHER = 98;

const SOLUTION_CATEGORIES = [
  CATEGORY_MOBILE,
  CATEGORY_STATIC,
  CATEGORY_HELP_AT_HOME,
  CATEGORY_ASSISTED,
  CATEGORY_HOSPICE,
  CATEGORY_OTHER,
  CATEGORY_SHORT_TERM_STATIC,
];

type SolutionCategories = (typeof SOLUTION_CATEGORIES)[number];

const FUNNEL_SOLUTIONS_MAP: Record<SolutionCategories, FilterValueSolutions> = {
  [CATEGORY_MOBILE]: [
    SOLUTION_MOBILE_CARE,
    SOLUTION_DAY_CARE,
    SOLUTION_NIGHT_CARE_MOBILE,
  ],
  [CATEGORY_STATIC]: [SOLUTION_STATIC_CARE, SOLUTION_NIGHT_CARE_STATIC],
  [CATEGORY_SHORT_TERM_STATIC]: [SOLUTION_SHORT_TERM_STATIC_CARE],
  [CATEGORY_HELP_AT_HOME]: [
    SOLUTION_HELP_AT_HOME,
    SOLUTION_DAILY_LIVING_SUPPORT_SERVICES,
  ],
  [CATEGORY_HOSPICE]: [
    SOLUTION_HOSPICE_ASSOCIATION,
    SOLUTION_HOSPICE_MOBILE,
    SOLUTION_HOSPICE_PARTIALLY_MOBILE,
    SOLUTION_HOSPICE_STATIC,
  ],
  [CATEGORY_ASSISTED]: [SOLUTION_SHARED_FLAT, SOLUTION_ASSISTED_LIVING],
  [CATEGORY_OTHER]: [PSEUDO_SOLUTION_OTHER],
};

const getTrackingTranslation = (category: SolutionCategories | undefined) => {
  switch (category) {
    case CATEGORY_MOBILE:
      return "Mobile care";
    case CATEGORY_STATIC:
      return "Static care";
    case CATEGORY_HELP_AT_HOME:
      return "Help at home";
    case CATEGORY_HOSPICE:
      return "Hospice";
    case CATEGORY_ASSISTED:
      return "Assisted living/shared flat";
    case CATEGORY_SHORT_TERM_STATIC:
      return "Short term static care";
    default:
      return "Other";
  }
};

const solutionsToCategory = (solutions: FilterValueSolutions) => {
  if (!solutions) return undefined;
  const funnelSolutions: FilterValueSolutions = [];

  Object.entries(FUNNEL_SOLUTIONS_MAP).forEach(([key, mappedValues]) => {
    if (mappedValues.some((value) => solutions.includes(value))) {
      funnelSolutions.push(parseInt(key));
    }
  });
  return funnelSolutions;
};

const modelDefinition = convertModelDefinition({
  ...valueDef("solutions", {
    convertIn: (input) => {
      if (!input) return null;
      const inputValue = Iterable.isIterable(input) ? input.toJS() : input;

      return solutionsToCategory(inputValue);
    },
    convertOut: (output) => {
      if (!output) return null;
      const outputValue = Iterable.isIterable(output) ? output.toJS() : output;
      return outputValue.flatMap(
        (category: number) => FUNNEL_SOLUTIONS_MAP[category],
      );
    },
  }),
});

export function Solutions({
  formData,
  setCurrentStep,
  setFormData,
}: {
  formData: FunnelFormData;
  setCurrentStep: React.Dispatch<React.SetStateAction<FunnelSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<FunnelFormData>>;
}) {
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const getRedirectLink = useGetProviderSearchRedirectLink();

  usePageMetaData({
    title: (translations) =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.solutionsTitle,
      ),
    description: (translations) =>
      translations.providersearch.pageTitles.solutionsDescription,
  });

  const options: ChipGroupPresenterProps["options"] = [
    {
      name: translations.providersearch.onboardingFunnel.secondStep.mobileCare,
      value: CATEGORY_MOBILE,
    },
    {
      name: translations.providersearch.onboardingFunnel.secondStep
        .shortTermCare,
      value: CATEGORY_SHORT_TERM_STATIC,
    },
    {
      name: translations.providersearch.onboardingFunnel.secondStep.staticCare,
      value: CATEGORY_STATIC,
    },
    {
      name: translations.providersearch.onboardingFunnel.secondStep.helpAtHome,
      value: CATEGORY_HELP_AT_HOME,
    },
    {
      name: translations.providersearch.onboardingFunnel.secondStep.sharedFlat,
      value: CATEGORY_ASSISTED,
    },
    {
      name: translations.providersearch.onboardingFunnel.secondStep.hospice,
      value: CATEGORY_HOSPICE,
    },
    {
      name: translations.providersearch.onboardingFunnel.answerOther,
      value: CATEGORY_OTHER,
    },
  ];

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={formData}
      modelDefinition={modelDefinition}
      onSubmit={({ solutions }: { solutions: FilterValueSolutions }) => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_SOLUTION_SELECTED,
          solutions,
          selection: solutionsToCategory(solutions)?.map((category) =>
            getTrackingTranslation(category),
          ),
        });

        setFormData({
          ...formData,
          solutions,
        });
        setCurrentStep(STEP_SERVICES);
      }}
    >
      {({ submit }) => {
        return (
          <FormStepWrapper
            formData={formData}
            skipLink={getRedirectLink({
              formData: { ...formData, solutions: undefined },
            })}
            step={STEP_SOLUTIONS}
            testId="solutions-select-wrapper"
            title={
              translations.providersearch.onboardingFunnel.secondStep.question
            }
          >
            <>
              <ChipGroupPresenter
                chipStyle={{
                  height: sizing(6),
                  justifyContent: "center",
                  width: "100%",
                }}
                elementName="solutions"
                formControlSx={{ padding: padding(0, 3) }}
                formGroupSx={{
                  flexDirection: "column",
                  margin: margin(1, 0),
                }}
                formLabelSx={{
                  fontSize: FONT_SIZE_20,
                  margin: margin(0, 0, 2),
                  ...visuallyHidden,
                }}
                options={options}
                squared
                title={getAccessibleTitleWithStep({
                  step: STEP_SOLUTIONS,
                  title:
                    translations.providersearch.onboardingFunnel.secondStep
                      .question,
                  translations,
                })}
              />
              <InfoTextWithGlossaryLink funnelStep={STEP_SOLUTIONS} />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: padding(3, 0, 2),
                }}
              >
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                    padding: padding(0, 3),
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="go-back-on-solutions-step"
                  loading="na"
                  type="button"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      solutions: undefined,
                    });
                    setCurrentStep(STEP_CONSULTANT_PROVIDER_SELECTION);
                  }}
                  variant="outlined"
                >
                  {translations.providersearch.onboardingFunnel.backButton}
                </RSButton>
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                    padding: padding(0, 3),
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="confirm-solutions"
                  loading="na"
                  type="submit"
                  onClick={submit}
                  variant="contained"
                >
                  {translations.providersearch.onboardingFunnel.nextButton}
                </RSButton>
              </div>
            </>
          </FormStepWrapper>
        );
      }}
    </SimpleFormRenderProps>
  );
}
