import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import { APP_PROVIDER_SEARCH } from "core/consts";
import Router from "./Router";

export type ProviderSearchContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_PROVIDER_SEARCH,
  services,
});

const services = ServiceFactory("ProviderSearch");
const context = Context(services);
RenderApp(context, Router);
