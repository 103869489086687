import { DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { LogoutIcon } from "ds/icons";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { sizing } from "ds_legacy/materials/metrics";
import { Halo } from "dsl/atoms/Halo";
import { NUDGE_LOCALSTORAGE_KEY } from "dsl/atoms/HintNudge";
import { ProviderSearchModalActions } from "dsl/ecosystems/ProviderSearchAssessmentModal/shared";
import {
  DialogContentWrapper,
  MODAL_MIN_WIDTH,
  ProviderSearchDialog,
} from "dsl/ecosystems/ProviderSearchLoginModal/shared";
import { useProvidersearchNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { useOnLogout } from "reduxentities/actions";
import { useTranslations } from "translations";

const useOnLogoutProviderSearch = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const { goToOnboardingFunnel } = useProvidersearchNavigationHandlers();
  const onLogout = useOnLogout();

  return () => {
    goToOnboardingFunnel();

    const nudgeValue = window.localStorage.getItem(NUDGE_LOCALSTORAGE_KEY);

    onLogout({ hardRefresh: true });

    if (nudgeValue) {
      window.localStorage.setItem(NUDGE_LOCALSTORAGE_KEY, nudgeValue);
    }

    onComplete();
  };
};

export const ConfirmLogoutDialog = ({ onClose }: { onClose: () => void }) => {
  const translations = useTranslations();
  const theme = useTheme();
  const onLogout = useOnLogoutProviderSearch({ onComplete: onClose });

  return (
    <ProviderSearchDialog
      onClose={onClose}
      paperStyle={{ maxWidth: MODAL_MIN_WIDTH, width: "100%" }}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <DialogContentWrapper testId="logout-conformation">
        <HorizontalLayout justify="center" width="100%">
          <Halo
            backgroundColor={theme.palette.error.light}
            padding={sizing(1.5)}
          >
            <LogoutIcon
              style={{
                fontSize: sizing(5),
                color: theme.palette.neutrals.base_dark,
              }}
              size={sizing(5)}
            />
          </Halo>
        </HorizontalLayout>
        <DialogTitle sx={{ wordBreak: "break-word" }} textAlign="center">
          {translations.providersearch.logoutModal.title}
        </DialogTitle>
        <DialogContentText textAlign="center">
          {translations.providersearch.logoutModal.subtitle}
        </DialogContentText>
      </DialogContentWrapper>
      <ProviderSearchModalActions
        cancelProps={{ id: "cancel" }}
        cancelText={translations.providersearch.logoutModal.buttonCancel}
        onCancel={onClose}
        submitProps={{ id: "confirm" }}
        onSubmit={onLogout}
        submitText={translations.providersearch.logoutModal.buttonLogout}
      />
    </ProviderSearchDialog>
  );
};
