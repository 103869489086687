import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import type { AriaPopoverProps } from "@react-aria/overlays";
import {
  DismissButton,
  Overlay,
  usePopover,
  usePreventScroll,
} from "@react-aria/overlays";
import { ComboBoxState } from "@react-stately/combobox";
import { Z_INDEX_MAX, border } from "ds_legacy/materials/metrics";
import { SHADOW_DP4 } from "ds_legacy/materials/shadows";
import { ReactNode, RefObject, useRef } from "react";

export type PopoverProps = Omit<AriaPopoverProps, "popoverRef"> & {
  children: ReactNode;
  elementName: string;
  popoverRef?: RefObject<HTMLDivElement>;
  state: ComboBoxState<object>;
};

/**
 * When rendered inside a MUI Dialog component, we need to use the same container as the MUI Dialog.
 * This function finds the closest ancestor of the current element that has the role="dialog" and returns it if found.
 **/
const findPortalContainer = (element: Element | null): Element | undefined => {
  if (!element) return;
  return element.closest('[role="dialog"]') ?? undefined;
};

export function Popover(props: PopoverProps) {
  usePreventScroll();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const { popoverRef = ref, state, children, triggerRef } = props;

  const {
    popoverProps: { style, ...popoverProps },
  } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay portalContainer={findPortalContainer(triggerRef.current)}>
      <Box
        {...popoverProps}
        ref={popoverRef}
        data-testid={`combobox-${props.elementName}-values`}
        style={{
          background: theme.palette.common.white,
          border: border({ color: theme.palette.grey["400"] }),
          boxShadow: SHADOW_DP4,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          width: (triggerRef.current as HTMLElement).offsetWidth,
          zIndex: Z_INDEX_MAX + 1,
          ...style,
        }}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </Box>
    </Overlay>
  );
}
