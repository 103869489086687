import { styled, useTheme } from "@mui/material";
import {
  GLOSSARY_SECTION_CARE_NEEDS,
  GLOSSARY_SECTION_SOLUTIONS,
  TRACK_EVENTS,
} from "core/consts";
import { getStaticAsset } from "core/model/config";
import LinkV2 from "ds_legacy/components/LinkV2";
import { HorizontalDivider, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  APP_BAR_PROVIDERSEARCH_HEIGHT,
  dp,
  important,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { shadow11dp } from "ds_legacy/materials/shadows";
import {
  Body,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
  Title,
} from "ds_legacy/materials/typography";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { ProviderSearchFilters } from "dsl/organisms/Filters/ProviderSearchApp/types";
import { ReactNode } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import Translations from "translations/types";
import { LinktoGlossary } from "../ProviderSearchGlossary/shared";

export const STEP_ZIPCODE = 1;
export const STEP_CONSULTANT_PROVIDER_SELECTION = 2;
export const STEP_SOLUTIONS = 3;
export const STEP_SERVICES = 4;
export const STEP_START_DATE = 5;

export const FUNNEL_STEPS = [
  STEP_ZIPCODE,
  STEP_CONSULTANT_PROVIDER_SELECTION,
  STEP_SOLUTIONS,
  STEP_SERVICES,
  STEP_START_DATE,
] as const;

export type FunnelFormData = Pick<
  ProviderSearchFilters,
  | "services"
  | "solutions"
  | "start_date"
  | "zipcode"
  | "with_consultants"
  | "with_providers"
  | "weight_interval"
>;

export type FunnelSteps = (typeof FUNNEL_STEPS)[number];

export const getStepperInfo = (step: FunnelSteps) => {
  // Stepper starts only after zipcode and consultant/provider selection
  const filteredSteps = FUNNEL_STEPS.filter(
    (step) =>
      ![STEP_ZIPCODE, STEP_CONSULTANT_PROVIDER_SELECTION].includes(step),
  );

  const currentStepIndex = filteredSteps.indexOf(step);
  const currentStep = currentStepIndex + 1;

  return {
    totalSteps: filteredSteps.length,
    stepperStep: currentStep,
  };
};

export const getAccessibleTitleWithStep = ({
  step,
  title,
  translations,
}: {
  step: FunnelSteps;
  title: string;
  translations: Translations;
}): string => {
  const { stepperStep, totalSteps } = getStepperInfo(step);

  if (!stepperStep) {
    throw new Error("[getAccessibleTitleWithStep] stepperStep is required");
  }

  return `${translations.providersearch.onboardingFunnel.stepper({
    step: stepperStep.toString(),
    totalSteps: totalSteps.toString(),
  })},
  ${title}`;
};

export const StepperPage = ({ children }: { children: ReactNode }) => {
  return (
    <VerticalLayout
      aligned
      justify="center"
      data-testid="provider-search-zipcode-paper"
      boxSizing="border-box"
      style={{
        padding: padding(2, 0),
        minHeight: `calc(100vh - ${dp(APP_BAR_PROVIDERSEARCH_HEIGHT)})`,
        backgroundImage: `url(${getStaticAsset("bcp-zipcode-background.jpg")})`,
      }}
    >
      {children}
    </VerticalLayout>
  );
};

const StyledTitle = styled(Title)`
  font-weight: ${FONT_WEIGHT_BOLD};
  align-self: flex-start;
  &:focus-visible {
    outline: ${important("none")};
  }
`;

const GLOSSARY_STEP_MAP = {
  [STEP_SOLUTIONS]: GLOSSARY_SECTION_SOLUTIONS,
  [STEP_SERVICES]: GLOSSARY_SECTION_CARE_NEEDS,
} as const;

export const FormStepWrapper = ({
  children,
  formData,
  skipLink,
  step,
  testId,
  title,
}: {
  children: ReactNode;
  formData: FunnelFormData;
  skipLink?: string;
  step: FunnelSteps;
  testId: string;
  title: string;
}) => {
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const translations = useTranslations();
  const isZipcodeSet = Boolean(formData.zipcode);

  const focusableElement = useFocusElement<HTMLHeadingElement>({
    setTabIndex: true,
    shouldFocus: isZipcodeSet,
  });

  const { stepperStep, totalSteps } = getStepperInfo(step);

  const glossarySection =
    GLOSSARY_STEP_MAP[step as keyof typeof GLOSSARY_STEP_MAP];

  return (
    <VerticalLayout
      aligned
      style={{
        boxSizing: "border-box",
        width: "100%",
        maxWidth: dp(439),
        minHeight: dp(406),
        padding: padding(3),
        backgroundColor: "white",
        boxShadow: shadow11dp,
      }}
    >
      <StyledTitle
        margin={stepperStep ? margin(0, 3, 2, 3) : margin(2, 3)}
        ref={focusableElement}
      >
        {stepperStep > 0 && (
          <TranslationComposition
            translations={translations.providersearch.onboardingFunnel.stepperFormatted(
              {
                step: stepperStep.toString(),
                totalSteps: totalSteps.toString(),
              },
            )}
            withOptions
          >
            {([before, selectValue, after]) => (
              <Body as="span" margin={margin(0, 0, 1)} fontSize={FONT_SIZE_16}>
                <span
                  style={{
                    color: theme.palette.neutrals.dark_grey,
                  }}
                >
                  {before}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {selectValue}
                  </span>
                  {after}
                </span>
              </Body>
            )}
          </TranslationComposition>
        )}
        {title}
      </StyledTitle>
      {glossarySection && (
        <LinktoGlossary
          funnelStep={step}
          margin={margin(-1, 0, 1, 3)}
          section={glossarySection}
        />
      )}
      <VerticalLayout data-testid={testId} width="100%">
        {children}
      </VerticalLayout>
      {skipLink && (
        <>
          <HorizontalDivider
            color={theme.palette.neutrals.medium_grey}
            style={{ width: "100vw" }}
          />
          <Body
            as="p"
            margin={`${sizing(2)} auto 0`}
            fontWeight={FONT_WEIGHT_MEDIUM}
            textAlign="center"
          >
            <LinkV2
              to={skipLink}
              color={theme.palette.neutrals.base_dark}
              onClick={() =>
                trackEvent({
                  name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_SKIPPED,
                  step,
                })
              }
            >
              {translations.providersearch.onboardingFunnel.skipButton}
            </LinkV2>
          </Body>
        </>
      )}
    </VerticalLayout>
  );
};

export const InfoTextWithGlossaryLink = ({
  funnelStep,
}: {
  funnelStep: FunnelSteps;
}) => {
  const translations = useTranslations();

  return (
    <TranslationComposition
      withOptions
      translations={
        translations.providersearch.onboardingFunnel
          .textDontKnowWithGlossaryLink
      }
    >
      {([before, _, after]) => (
        <Body as="p" style={{ padding: padding(1, 3) }} margin={margin(0)}>
          {before}
          <LinktoGlossary
            funnelStep={funnelStep}
            short
            linkSx={{ fontSize: "inherit" }}
          />
          {after}
        </Body>
      )}
    </TranslationComposition>
  );
};
