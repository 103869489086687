import {
  DialogActions,
  DialogActionsProps,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { PersonOutlineIcon } from "ds/icons";
import RSButton, { RSButtonProps } from "ds_legacy/components/RSButton";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_20, FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import { Halo } from "dsl/atoms/Halo";
import { useMedia } from "dsl/atoms/ResponsiveMedia";

export const ASSESSMENT_MODAL_STEP_AUCTION = 1;
export const ASSESSMENT_MODAL_STEP_PATIENT_SELECTOR = 2;
export const ASSESSMENT_MODAL_STEP_ASSESSMENT = 3;
export const ASSESSMENT_MODAL_STEP_REQUEST = 4;

export type AssessmentModalStep =
  | typeof ASSESSMENT_MODAL_STEP_AUCTION
  | typeof ASSESSMENT_MODAL_STEP_PATIENT_SELECTOR
  | typeof ASSESSMENT_MODAL_STEP_ASSESSMENT
  | typeof ASSESSMENT_MODAL_STEP_REQUEST
  | null;

export const ModalHeader = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <>
      <HorizontalLayout justify="center" width="100%">
        <Halo
          backgroundColor={theme.palette.neutrals.base_light}
          padding={sizing(1.5)}
        >
          <PersonOutlineIcon
            style={{ fontSize: sizing(7.5) }}
            size={sizing(7.5)}
          />
        </Halo>
      </HorizontalLayout>
      <DialogTitle
        fontWeight={FONT_WEIGHT_BOLD}
        textAlign="center"
        fontSize={FONT_SIZE_20}
        margin={margin(0)}
        sx={{ padding: padding(2, 0) }}
      >
        {title}
      </DialogTitle>
    </>
  );
};

export function ProviderSearchModalActions({
  cancelProps,
  cancelText,
  dialogActionsSx,
  onCancel,
  onSubmit,
  submitProps,
  submitText,
}: {
  cancelProps: Partial<RSButtonProps>;
  cancelText: string;
  dialogActionsSx?: DialogActionsProps["sx"];
  onCancel: () => void;
  onSubmit: () => void;
  submitProps: Partial<RSButtonProps>;
  submitText: string;
}) {
  const { isMobile } = useMedia();

  return (
    <DialogActions
      sx={{
        justifyContent: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: padding(2, 3),
        margin: margin(0),
        ...dialogActionsSx,
      }}
    >
      <RSButton
        {...(cancelProps as RSButtonProps)}
        buttonWrapperStyle={{ margin: margin(0, 1.5, 0) }}
        style={{ margin: 0, width: isMobile ? "auto" : sizing(20) }}
        color="primary"
        loading={cancelProps.loading ?? "na"}
        onClick={onCancel}
        variant="outlined"
      >
        {cancelText}
      </RSButton>
      <RSButton
        {...(submitProps as RSButtonProps)}
        buttonWrapperStyle={{ margin: 0 }}
        style={{ margin: 0, width: sizing(20) }}
        color="primary"
        type="submit"
        loading={submitProps.loading ?? "na"}
        inverted
        onClick={onSubmit}
        variant="outlined"
      >
        {submitText}
      </RSButton>
    </DialogActions>
  );
}
