import { Divider } from "@mui/material";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import { margin } from "ds_legacy/materials/metrics";
import { Caption, FONT_SIZE_12, Link } from "ds_legacy/materials/typography";
import { TranslationComposition } from "translations/helpers";
import { ProviderSearchLoginModalType } from "./shared";

export const GoToModal = ({
  goToModal,
  translation,
}: {
  goToModal: ProviderSearchLoginModalType;
  translation: string;
}) => {
  const { setLoginModal } = useProviderSearchContext();

  return (
    <>
      <Divider sx={{ margin: margin(1), width: "100%" }} />
      <Caption
        margin={margin(1, 0)}
        fontSize={FONT_SIZE_12}
        whiteSpace="normal"
      >
        <TranslationComposition translations={translation} withOptions>
          {([before, link, after]) => (
            <>
              {before}
              <Link
                type="button"
                role="button"
                primary
                onClick={() => setLoginModal(goToModal)}
                data-testid="login-error-link"
              >
                {link}
              </Link>
              {after}
            </>
          )}
        </TranslationComposition>
      </Caption>
    </>
  );
};
