import {
  Box,
  FormHelperTextProps,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useButton } from "@react-aria/button";
import { useComboBox } from "@react-aria/combobox";
import { Item } from "@react-stately/collections";
import {
  ComboBoxStateOptions,
  useComboBoxState,
} from "@react-stately/combobox";
import { getAriaDescribedByValue } from "core/model/utils/strings";
import { ExpandMoreIcon } from "ds/icons";
import { CUSTOM_BLACK } from "ds_legacy/materials/colors";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import {
  UseCustomAlgoliaSearchClientProps,
  useAlgoliaSearchClient,
} from "dsl/hooks/useAlgoliaSearchClient";
import { debounce } from "lodash";
import {
  CSSProperties,
  Key,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FormElementProps,
  FormElementRenderProps,
  isValid,
} from "react-forms-state";
import {
  AutocompleteExposed,
  AutocompleteProvided,
  BasicDoc,
  Hit,
} from "react-instantsearch-core";
import {
  Configure,
  InstantSearch,
  connectAutoComplete,
} from "react-instantsearch-dom";
import { useTranslations } from "translations";
import { getHelperText } from "../Validation";
import { ListBox } from "./ListBox";
import { Popover } from "./Popover";

type ConfigureProps = {
  filters?: string;
  hitsPerPage?: number;
};

export type ComboBoxProps<T extends object> = ComboBoxStateOptions<T> & {
  ariaDescribedBy?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  elementName: string;
  formControlSx?: CSSProperties;
  inputSx?: CSSProperties;
  labelSx?: CSSProperties;
  noOptionsText?: string;
  textFieldInputProps?: TextFieldProps["InputProps"];
  variant?: TextFieldProps["variant"];
  wrapperSx?: CSSProperties;
};

export type ConnectedComboBoxProps<T extends object> = Omit<
  ComboBoxProps<T>,
  "onSelectionChange" | "isInvalid" | "errorMessage"
> &
  Pick<FormElementProps, "onChange">;

export type AlgoliaAutoCompleteBaseProps<
  TDoc extends BasicDoc,
  T extends object,
> = {
  getOptionLabel: (hit: Hit<TDoc>) => string;
  getValue: (hit: Hit<TDoc>) => T | null;
  minCharsForRefinement?: number;
  renderOption?: (hit: Hit<TDoc>) => ReactNode;
};

type AlgoliaAutoCompleteProps<
  TDoc extends BasicDoc,
  T extends object,
> = AlgoliaAutoCompleteBaseProps<TDoc, T> &
  AutocompleteExposed &
  Omit<ComboBoxProps<T>, "children" | "allowCustomValue" | "defaultInputValue">;

type ConnectedAlgoliaAutoCompleteProps<
  TDoc extends BasicDoc,
  T extends object,
> = Pick<FormElementProps, "elementName"> &
  Omit<
    AlgoliaAutoCompleteProps<TDoc, T>,
    | "currentRefinement"
    | "hits"
    | "onChange"
    | "refine"
    | "children"
    | "onSelectionChange"
    | "isInvalid"
    | "errorMessage"
    | "formValue"
  >;

export type AlgoliaComboBoxProps<
  TDoc extends BasicDoc,
  T extends object,
  Connected extends boolean,
> = (Connected extends true
  ? Omit<ComboBoxProps<T>, "onSelectionChange" | "isInvalid" | "errorMessage">
  : Omit<ComboBoxProps<T>, "onSelectionChange"> & {
      onSelectionChange: (value: T | null) => void;
    }) &
  AlgoliaAutoCompleteBaseProps<TDoc, T> &
  ConfigureProps &
  UseCustomAlgoliaSearchClientProps & { connected?: Connected };

export function ComboBox<T extends object>({
  elementName,
  formControlSx,
  inputSx,
  labelSx,
  noOptionsText,
  textFieldInputProps,
  variant = "standard",
  wrapperSx,
  ...props
}: ComboBoxProps<T>) {
  const translations = useTranslations();
  const errorTextId = `${elementName}_errorText`;
  const state = useComboBoxState({
    allowsEmptyCollection: !!noOptionsText,
    ...props,
    defaultFilter(textValue, inputValue) {
      const lowerCaseTextValue = textValue.toLowerCase();
      const lowerCaseInputValue = inputValue.toLowerCase();
      return lowerCaseTextValue.includes(lowerCaseInputValue);
    },
  });

  const triggerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const {
    buttonProps: triggerProps,
    inputProps,
    labelProps,
    listBoxProps,
  } = useComboBox(
    {
      shouldFocusWrap: true,
      "aria-label": props.ariaLabel,
      "aria-labelledby": props.ariaLabelledBy,
      "aria-describedby":
        getAriaDescribedByValue([props.isInvalid && errorTextId]) ||
        props.ariaDescribedBy,
      ...props,
      buttonRef: buttonRef,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const { buttonProps } = useButton(
    {
      ...triggerProps,
      "aria-label":
        translations.providersearch.listingPage.accessibility
          .suggestionsAriaLabel,
    },
    buttonRef,
  );

  return (
    <Box
      ref={triggerRef}
      sx={{
        ...wrapperSx,
        position: "relative",
        display: "inline-block",
      }}
      data-testid={`combobox-wrapper-${elementName}`}
    >
      <TextField
        disabled={props.isDisabled}
        error={props.isInvalid}
        FormHelperTextProps={{
          id: errorTextId,
          ...({
            "data-testid": `combobox-helpertext-${elementName}`,
          } as FormHelperTextProps),
        }}
        helperText={props.errorMessage as ReactNode}
        InputLabelProps={{
          ...labelProps,
          sx: {
            ...labelProps.style,
            fontSize: FONT_SIZE_14,
            color: CUSTOM_BLACK,
            ...labelSx,
          },
        }}
        inputProps={{
          "data-testid": `combobox-${elementName}`,
          name: elementName,
          ...inputProps,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                {...buttonProps}
                color="inherit"
                ref={buttonRef}
                size="small"
                sx={{ zIndex: 1, ...buttonProps.style }}
              >
                <ExpandMoreIcon size={FONT_SIZE_14} />
              </IconButton>
            </InputAdornment>
          ),
          ...textFieldInputProps,
          sx: {
            ...inputProps.style,
            fontSize: FONT_SIZE_14,
            "& .MuiInputBase-input::placeholder": {
              opacity: 0.8, // To ensure color contrast requirements
            },
            ...inputSx,
          },
        }}
        inputRef={inputRef}
        label={props.label}
        required={props.isRequired}
        size="small"
        sx={{
          fontSize: FONT_SIZE_14,
          width: "100%",
          ...formControlSx,
        }}
        variant={variant}
      />
      {state.isOpen && (
        <Popover
          elementName={elementName}
          isNonModal
          placement="bottom start"
          popoverRef={popoverRef}
          state={state}
          triggerRef={triggerRef}
        >
          <ListBox
            {...listBoxProps}
            shouldUseVirtualFocus // Use virtual focus to get aria-activedescendant tracking and ensure focus doesn't leave the input field
            listBoxRef={listBoxRef}
            state={state}
            noOptionsText={noOptionsText}
          />
        </Popover>
      )}
    </Box>
  );
}

export function ConnectedComboBox<T extends object>({
  onChange,
  ...props
}: ConnectedComboBoxProps<T>) {
  const translations = useTranslations();
  return (
    <FormElementRenderProps elementName={props.elementName} onChange={onChange}>
      {({ onChange, validation, ...rest }) => {
        return (
          <ComboBox
            {...props}
            {...rest}
            errorMessage={getHelperText({
              hasCustomValidation: true,
              translations,
              validation,
            })}
            isInvalid={!isValid(validation)}
            onSelectionChange={onChange}
            elementName={props.elementName}
          />
        );
      }}
    </FormElementRenderProps>
  );
}

function ConnectedAlgoliaAutoComplete<TDoc extends BasicDoc, T extends object>(
  props: ConnectedAlgoliaAutoCompleteProps<TDoc, T>,
) {
  const translations = useTranslations();

  return (
    <FormElementRenderProps elementName={props.elementName}>
      {({ getValue: _, onChange, validation, value, ...rest }) => {
        return (
          <AlgoliaAutoComplete
            {...props}
            {...rest}
            defaultRefinement={value ? props.getOptionLabel(value) : undefined}
            elementName={props.elementName}
            errorMessage={getHelperText({
              hasCustomValidation: true,
              translations,
              validation,
            })}
            isInvalid={!isValid(validation)}
            onSelectionChange={onChange}
          />
        );
      }}
    </FormElementRenderProps>
  );
}

const AlgoliaAutoComplete = connectAutoComplete(
  ({
    currentRefinement,
    getOptionLabel,
    getValue,
    hits,
    minCharsForRefinement = 1,
    onSelectionChange,
    refine,
    ...props
  }: AutocompleteProvided & AlgoliaAutoCompleteProps<any, any>) => {
    const [search, setSearch] = useState<string>(currentRefinement ?? "");
    const [fieldState, setFieldState] = useState<{
      inputValue: string;
      items: Hit<BasicDoc>[];
      selectedKey: Key;
    }>({
      selectedKey: "",
      inputValue: currentRefinement ?? "",
      items: [],
    });

    const debouncedSetSearch = debounce(setSearch, 200);

    const handleInputChange: ComboBoxProps<BasicDoc>["onInputChange"] = (
      value,
    ) => {
      setFieldState((prev) => ({
        ...prev,
        inputValue: value,
        selectedKey: value === "" ? "" : prev.selectedKey,
      }));

      debouncedSetSearch(value);

      if (value === "") {
        onSelectionChange?.("");
      }
    };

    const handleSelectionChange: ComboBoxProps<BasicDoc>["onSelectionChange"] =
      (objectID) => {
        const selectedItem = hits.find((hit) => hit.objectID === objectID);
        onSelectionChange?.(selectedItem ? getValue(selectedItem) : null);
        setFieldState((prev) => ({
          ...prev,
          selectedKey: objectID,
          inputValue: selectedItem ? getOptionLabel(selectedItem) : "",
        }));
      };

    useEffect(() => {
      if (fieldState.inputValue.length >= minCharsForRefinement) {
        refine(fieldState.inputValue);
      }
    }, [search]);

    useEffect(() => {
      if (currentRefinement !== fieldState.inputValue) {
        setFieldState((prev) => ({
          ...prev,
          inputValue: currentRefinement ?? "",
        }));
      }
    }, [currentRefinement]);

    useEffect(() => {
      if (hits.length) {
        setFieldState((prev) => ({ ...prev, items: hits }));
      }
    }, [hits]);

    useEffect(() => {
      /**
       * Determines the selected key based on specific conditions:
       * If there is only one hit, the input value matches the label of that hit,
       * then use its objectID as the selected key, otherwise let ComboBox component figure it out itself.
       * The reason for this is that when a initial input value is provided, we want that value to be selected.
       */
      if (
        hits.length === 1 &&
        fieldState.inputValue &&
        getOptionLabel(hits[0]) === fieldState.inputValue
      ) {
        setFieldState((prev) => ({
          ...prev,
          selectedKey: hits[0].objectID,
        }));
      }
    }, [hits, fieldState.inputValue]);

    return (
      <ComboBox
        {...props}
        allowsCustomValue
        inputValue={fieldState.inputValue}
        onInputChange={handleInputChange}
        onSelectionChange={handleSelectionChange}
        selectedKey={fieldState.selectedKey as any}
      >
        {fieldState.items.map((hit) => {
          // 'key' is essentially to getting the correct item
          return (
            <Item key={hit.objectID} textValue={getOptionLabel(hit)}>
              {props.renderOption
                ? props.renderOption(hit)
                : getOptionLabel(hit)}
            </Item>
          );
        })}
      </ComboBox>
    );
  },
);

export function AlgoliaComboBox<
  TDoc extends BasicDoc,
  T extends object,
  Connected extends boolean = boolean,
>({
  algoliaAnalyticsName,
  connected,
  filters,
  hitsPerPage = 50,
  indexName,
  indexWithEnv,
  ...props
}: AlgoliaComboBoxProps<TDoc, T, Connected>) {
  const { analyticsTags, indexNameWithEnv, searchClient } =
    useAlgoliaSearchClient({ algoliaAnalyticsName, indexName, indexWithEnv });

  return (
    <InstantSearch searchClient={searchClient} indexName={indexNameWithEnv}>
      <Configure
        analyticsTags={analyticsTags}
        clickAnalytics
        filters={filters}
        hitsPerPage={hitsPerPage}
      />
      {connected ? (
        <ConnectedAlgoliaAutoComplete {...props} />
      ) : (
        <AlgoliaAutoComplete
          {...props}
          defaultRefinement={props.defaultInputValue}
        />
      )}
    </InstantSearch>
  );
}
